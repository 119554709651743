import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/genLink/:did/:mid', component: () => import('@/views/Index') },
    { path: '/deviceMgr/:did/:mid', component: () => import('@/views/WSIndex') },
    { path: '/error', component: () => import('@/views/Error') }
]
const router = new VueRouter({
    mode: 'history',
    routes
})

// router.beforeEach((to, from, next) => {
    // document.title = to.title ?? to.name ?? ''

    // Vue.prototype.$changeTopMenu ? Vue.prototype.$changeTopMenu([]) : ''
    // next();
// });

export default router
