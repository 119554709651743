<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: 'App',
  // mounted() {
  //   axios.get('http://api.eyegroup.cc/wx/codeToAccessToken', {code: this.$route.query.code}).then(res => {
  //     console.log(res)
  //   })
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
